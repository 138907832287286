/* ################ */
/* Projects section */
/* ################ */
.col-xl-6,
.col-xl-12 {
  padding: 0;
}
.card {
  background-color: var(--card-color);
  /* padding: 10px; */
  padding-top: 1rem;
  align-items: center;
  margin: 10px;
}
.cardImg {
  margin: 5px;
  height: 60px;
  object-fit: contain;
}
.card-subtitle {
  color: var(--dark-secondary-text-color);
}
.link-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.btn {
  margin: 5px;
  font-weight: bold;
  .icon {
    font-weight: 600;
    width: 1.5rem;
    height: 1.5rem;
  }
}
.modal {
  --bs-modal-width: 800px;
}
.modal-box {
  cursor: pointer;
}
.modal-content {
  background-color: #212529;
  .project-carousel-img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
  .carousel {
    margin-bottom: 30px;
  }
  .project-description {
    margin-bottom: 20px;
    font-size: medium;
  }
  .tags .col {
    border-left: lightgray 1px solid;
  }
  .tags .col:first-child {
    border-left: none;
  }
  .icon {
    margin-right: 10px;
  }
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}

/* ############### */
/* Contact section */
/* ############### */
#contact {
  min-height: 95vh;
}
#contact input,
#contact textarea {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
#contact textarea {
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

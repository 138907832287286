:root {
  /* Background color */
  --dark-main-background-color: #161a30;
  --dark-secondary-background-color: #31304d;
  --light-main-background-color: #f0ece5;

  /* Text color */
  --name-color: #9ad0c2;
  --dark-main-text-color: #f0ece5;
  --dark-secondary-text-color: #b6bbc4;
  --light-main-text-color: #161a30;
  --light-secondary-text-color: #444444;

  /* Icon color */
  --dark-icon-color: #f0ece5;
  --light-icon-color: #31304d;
  --icon-hover-color: #2d9596;

  /* Background image */
  --main-img-background-color: white;
  --image-background-color: white;

  /* Card color */
  --card-color: #435585;
}

/* ####### */
/* General */
/* ####### */
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  background-color: var(--dark-main-background-color);
  color: var(--dark-main-text-color);
  text-align: center;
}
p {
  font-size: 1.1rem;
}
.lightSection {
  background-color: var(--light-main-background-color);
  color: var(--light-main-text-color);
  margin-left: 0;
  margin-right: 0;
  min-width: 100%;
}
.secondaryHeader {
  font-family: "Work Sans", sans-serif;
  color: var(--dark-secondary-text-color);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 30px;
}
.lightSection .secondaryHeader {
  color: var(--light-secondary-text-color);
}
.btn {
  padding: 10px 20px;
}
.highlight {
  color: var(--name-color);
}
.sections {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  transition: 0.15s;
  width: 2rem;
  height: 2rem;
}
.icon:hover {
  color: var(--icon-hover-color) !important;
  transition: 0.15s;
}

/* ########### */
/* NAV section */
/* ########### */
.mainNavBar {
  background-color: var(--dark-main-background-color);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.nav-link {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0;
  animation: slideUp 0.5s ease forwards;
  animation-delay: calc(0.2s * var(--i));
}
.navbar-toggler {
  border: none;
}
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* #################################### */
/* CSS on xs breakpoint of each section */
/* #################################### */

/* CSS to xs breakpoint */
@media (max-width: 576px) {
  .row {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .card {
    /* padding: 5px; */
    margin-left: 0px;
    margin-right: 0px;
  }
  .card img {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .card .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* #################################### */
/* CSS on lg breakpoint of each section */
/* #################################### */

/* CSS to lg breakpoint */
@media (min-width: 992px) {
  .sections:not(#home) {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
  }
}

/* ###### */
/* Footer */
/* ###### */
footer p {
  font-size: small;
}

/* ################# */
/* Education section */
/* ################# */
.edu-content {
  text-align: left;
}
#education .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--image-background-color);
  border-radius: 10px;
}
.edu-img {
  width: 100%;
  max-height: 100px;
}
#education .card-subtitle {
  color: var(--dark-main-text-color);
}
#education p {
  color: var(--dark-secondary-text-color);
}
@media (max-width: 767px) {
  #education .row {
    justify-content: center;
  }
  .image-container {
    max-width: 60%;
    padding: 10px;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  #education .card-body,
  #edcation .row {
    width: 80%;
  }
  .edu-content {
    padding-left: 50px;
  }
}

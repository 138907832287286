/* ############ */
/* Home section */
/* ############ */
#home {
  padding-top: 61px;
}
#mainHeader {
  font-family: "Roboto Mono", monospace;
  font-weight: 700;
  font-size: 4rem;
}
.secondaryHeader {
  display: inline;
}

/* CSS to xs breakpoint */
@media (max-width: 576px) {
  #home {
    height: 700px;
  }
  #mainHeader {
    font-size: 3rem;
  }
  .secondaryHeader {
    font-size: 2rem;
  }
  #mainImg {
    margin: 10px;
    width: 60%;
  }
}

/* CSS to sm breakpoint */
@media (min-width: 576px) {
  #home {
    height: 900px;
  }
  #mainImg {
    width: 70%;
    height: 100%;
    margin: 20px;
  }
  #mainHeader {
    font-size: 4rem;
  }
  .secondaryHeader {
    font-size: 2.5rem;
  }
}

/* CSS to md breakpoint */
@media (min-width: 768px) {
  #mainImg {
    width: 70%;
    height: 100%;
    margin: 30px;
  }
  #home {
    height: 700px;
  }
  #mainHeader {
    font-size: 3rem;
  }
  .secondaryHeader {
    font-size: 2rem;
  }
}

/* CSS to lg breakpoint */
@media (min-width: 992px) {
  #home {
    height: 700px;
  }
  #mainImg {
    margin: 30px;
    width: 50%;
  }
  #mainHeader {
    font-size: 4rem;
  }
  .secondaryHeader {
    font-size: 3rem;
  }
}

@keyframes float {
  0% {
    box-shadow: 0 2px 15px 0px rgba(240, 236, 229, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 15px 15px 0px rgba(240, 236, 229, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 2px 15px 0px rgba(240, 236, 229, 0.6);
    transform: translatey(0px);
  }
}
#mainImg {
  transform: translatey(0px);
  animation: float 4s ease-in-out infinite;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 5px 2px 0px rgba(240, 236, 229, 0.6);
  background-color: var(--main-img-background-color);
}
#home .btn {
  margin-top: 40px;
  margin-right: 20px;
  font-weight: 500;
}

#home .icon {
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  color: var(--dark-icon-color);
}
#home .link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
#aboutMe .icon-container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#aboutMe .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 15px;
  margin-left: 15px;
  color: var(--light-icon-color);
}

/* ################### */
/* Experiences section */
/* ################### */

#experiences {
  justify-content: center;
  img {
    width: 80%;
    border-radius: 5px;
    margin: 30px;
  }
  .experienceContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  hr {
    width: 100%;
  }
  li {
    text-align: left;
  }
  .card-body {
    padding: 0;
    padding-bottom: 1rem;
  }
}
